/**
  ReferralPageCard
 */

import React from 'react';
import { LinkType, StandardImage } from 'types/global';
import {
	AltCardWrapper,
	BottomArea,
	Container,
	Heading,
	ImageColumn,
	ImageColumnInner,
	AnchorTextAlt2,
	IconBadge,
	Preamble,
	TextColumnAlt,
	Category,
	DateArea,
	Date,
} from './Cards.styles';
import Image from 'components/Image';
import Icon from 'components/Icon';

interface Props {
	/** ReferralPage heading */
	heading: string;

	/** ReferralPage subHeading */
	subHeading: string;

	/** ReferralPage preamble */
	preamble?: string;

	/** ReferralPage fallback preamble */
	preambleFallback?: string;

	/** ReferralPage should use the fallback of preamble */
	usePreambleFallback: boolean;

	/** ReferralPage link */
	link: LinkType;

	/** Card index */
	index: number;

	/** Card id */
	id?: string;

	/** EpiFind hitId */
	hitId?: string;

	// backgroundColor: 'lightBlue' | 'blue' | 'white';
	image: StandardImage;

	hideImages?: boolean;

	date: string;

	category: string;

	translations: { registrationNumber: string };
}

/** Container component for ReportPageCard. */
const ReferralPageCard: React.FC<Props> = ({
	heading,
	subHeading,
	preamble,
	preambleFallback,
	usePreambleFallback,
	link,
	index,
	hideImages,
	id,
	hitId,
	image,
	date,
	category,
	translations,
}) => (
	<AltCardWrapper
		backgroundColor="white"
		aria-labelledby={`heading-${id}`}
		noImage={hideImages}
	>
		<Container
			href={link.link && typeof link.link === 'string' ? link.link : link}
			id={hitId || id}
		>
			{!hideImages && (
				<ImageColumn>
					<ImageColumnInner>
						<Image
							src={image.src}
							alt={image.alt}
							srcSet={`
                        ${image.sizes[0]} 500w,
                        ${image.sizes[1]} 350w
                    `}
							webpSrcSet={`
                        ${image.webpSizes[0]} 500w,
                        ${image.webpSizes[1]} 350w
                    `}
							sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 350px"
						/>
					</ImageColumnInner>
				</ImageColumn>
			)}

			<BottomArea>
				<DateArea>
					<Date>{date}</Date>
					<Category>
						{translations.registrationNumber} {category}
					</Category>
				</DateArea>

				<TextColumnAlt>
					<Heading as="h3" id={`heading-${id}`} backgroundColor="white">
						{heading}
					</Heading>
					{(preamble || usePreambleFallback) && (
						<Preamble backgroundColor="white">
							{preamble ?? preambleFallback}
						</Preamble>
					)}
				</TextColumnAlt>

				<AnchorTextAlt2 backgroundColor="white">
					{link.text}
					<IconBadge backgroundColor="white">
						<Icon icon={'arrowRight'} size={0} aria-hidden="true" />
					</IconBadge>
				</AnchorTextAlt2>
			</BottomArea>
		</Container>
	</AltCardWrapper>
);

export default ReferralPageCard;
