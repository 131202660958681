/**
  ThemePageCard
 */

import React from 'react';
import { LinkType, StandardImage } from 'types/global';
import {
	AltCardWrapper,
	BottomArea,
	Container,
	Heading,
	ImageColumn,
	ImageColumnInner,
	AnchorTextAlt2,
	IconBadge,
	Preamble,
	TextColumnAlt,
} from './Cards.styles';
import Image from 'components/Image';
import Icon from 'components/Icon';

interface Props {
	heading: string;
	id?: string;
	image: StandardImage;
	link: LinkType;
	preamble?: string;

	backgroundColor: 'lightBlue' | 'blue' | 'white';
	index: number;
}

/** Container component for ThemePageCard. */
const ThemePageCard: React.FC<Props> = ({
	backgroundColor,
	heading,
	id,
	image,
	index,
	link,
	preamble,
}) => (
	<AltCardWrapper
		backgroundColor={backgroundColor}
		aria-labelledby={`heading-${id}`}
	>
		<Container
			href={link.link && typeof link.link === 'string' ? link.link : link}
			id={id}
		>
			<ImageColumn>
				<ImageColumnInner>
					<Image
						src={image.src}
						alt={image.alt}
						srcSet={`
                        ${image.sizes[0]} 500w,
                        ${image.sizes[1]} 350w
                    `}
						webpSrcSet={`
                        ${image.webpSizes[0]} 500w,
                        ${image.webpSizes[1]} 350w
                    `}
						sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 350px"
					/>
				</ImageColumnInner>
			</ImageColumn>

			<BottomArea>
				<TextColumnAlt>
					<Heading
						as="h3"
						id={`heading-${id}`}
						backgroundColor={backgroundColor}
					>
						{heading}
					</Heading>

					{preamble && (
						<Preamble backgroundColor={backgroundColor}>{preamble}</Preamble>
					)}
				</TextColumnAlt>

				<AnchorTextAlt2 backgroundColor={backgroundColor}>
					{link.text}
					<IconBadge backgroundColor={backgroundColor}>
						<Icon icon={'arrowRight'} size={0} aria-hidden="true" />
					</IconBadge>
				</AnchorTextAlt2>
			</BottomArea>
		</Container>
	</AltCardWrapper>
);

export default ThemePageCard;
