/**
 * PromoBlock
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { StandardImage } from 'types/global';

import Image from 'components/Image';

import {
	ButtonContainer,
	Container,
	ImageColumn,
	SubHeading,
	TextColumn,
	Heading,
	Preamble,
	TextColumnInner,
	ImageColumnInner,
} from './PromoBlock.styles';
import BlockContainer from 'layouts/BlockLayout';

interface Props {
	/** Background color */
	backgroundColor: 'blueDark' | 'white';

	/** Block heading */
	heading: string;

	/** Image for desktop */
	image: StandardImage;

	/** Image align in desktop */
	imageAlign: 'left' | 'right';

	/** Image for mobil */
	imageMobile: StandardImage;

	/** Block kickerHeading */
	kickerHeading?: string;

	/** Block paragraph */
	text?: string;

	/** If buttons, should be handled as children */
	children?: any;

	isPageHeader?: boolean;
}

/** Promotional Block */
const PromoBlock: React.FC<Props> = ({
	backgroundColor = 'blueDark',
	heading = '',
	image,
	imageAlign,
	imageMobile,
	kickerHeading = '',
	text = '',
	isPageHeader = false,
	children = [],
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const imageToShow = isPhone ? imageMobile : image;

	return (
		<BlockContainer backgroundColor={backgroundColor}>
			<Container backgroundColor={backgroundColor} imageAlign={imageAlign}>
				{imageToShow && (
					<ImageColumn
						backgroundColor={backgroundColor}
						imageAlign={imageAlign}
					>
						<ImageColumnInner numberOfChildren={children.length}>
							<Image
								src={imageToShow.src}
								alt={imageToShow.alt}
								srcSet={
									isPhone
										? `
							 ${imageToShow.sizes[0]} 500w,
							 ${imageToShow.sizes[1]} 350w
						 `
										: `
							 ${imageToShow.sizes[0]} 800w,
							 ${imageToShow.sizes[1]} 500w,
							 ${imageToShow.sizes[2]} 350w
						 `
								}
								webpSrcSet={
									isPhone
										? `
							 ${imageToShow.webpSizes[0]} 500w,
							 ${imageToShow.webpSizes[1]} 350w
						 `
										: `
							 ${imageToShow.webpSizes[0]} 800w,
							 ${imageToShow.webpSizes[1]} 500w,
							 ${imageToShow.webpSizes[2]} 350w
						 `
								}
								sizes="(min-width: 1040px) 60vw, (min-width: 768px) 40vw, (max-width: 767px) 100vw, 800px"
							/>
						</ImageColumnInner>
					</ImageColumn>
				)}

				<TextColumn backgroundColor={backgroundColor} imageAlign={imageAlign}>
					<TextColumnInner imageAlign={imageAlign}>
						{kickerHeading && (
							<SubHeading as="h2" backgroundColor={backgroundColor}>
								{kickerHeading}
							</SubHeading>
						)}

						<Heading as={isPageHeader ? 'h1' : 'h3'}>{heading}</Heading>

						{text && (
							<Preamble backgroundColor={backgroundColor}>{text}</Preamble>
						)}

						{children && (
							<ButtonContainer
								backgroundColor={backgroundColor}
								$hasMoreThanTwoChilds={children.length > 2}
							>
								{children}
							</ButtonContainer>
						)}
					</TextColumnInner>
				</TextColumn>
			</Container>
		</BlockContainer>
	);
};

export default PromoBlock;
