/**
 * ThemeBannerBlock
 */

import React from 'react';
import {
	Heading,
	SubHeading,
	Text,
	FlexWrapper,
	LeftSide,
	RightSide,
	ImageContainer,
	ContentWrapper,
} from './ThemeBannerBlock.styles';
import { StandardImage } from 'types/global';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';

import BlockContainer from 'layouts/BlockLayout';
import Image from 'components/Image';

interface Props {
	/** Block heading */
	heading: string;

	/** Kicker heading */
	kickerHeading?: string;

	/** Block text content */
	text?: string;

	/** Block background color */
	backgroundColor?: 'blue' | 'white';

	/** Block image (desktop) */
	image: StandardImage;

	/** Block image (mobile) */
	imageMobile: StandardImage;

	/** Epi properties if in edit mode */
	_properties?: any;
}

/** Theme banner block. */
const ThemeBannerBlock: React.FC<Props> = ({
	heading,
	kickerHeading,
	text,
	backgroundColor = 'blue',
	image,
	imageMobile,
	_properties,
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const imageToShow = isPhone ? imageMobile : image;
	const textColor = backgroundColor === 'blue' ? 'white' : 'greyDark';

	return (
		<div>
			<BlockContainer backgroundColor={backgroundColor}>
				<ContentWrapper>
					{kickerHeading && (
						<SubHeading
							as="h1"
							$color={textColor}
							{..._properties?.kickerHeading}
						>
							{kickerHeading}
						</SubHeading>
					)}
					<FlexWrapper>
						<LeftSide>
							<Heading
								as={kickerHeading ? 'h2' : 'h1'}
								$color={textColor}
								{..._properties?.heading}
							>
								{heading}
							</Heading>
						</LeftSide>
						<RightSide>
							<Text $color={textColor} {..._properties?.text}>
								{text}
							</Text>
						</RightSide>
					</FlexWrapper>
				</ContentWrapper>
			</BlockContainer>

			{imageToShow && (
				<ImageContainer {..._properties?.image}>
					<Image
						src={imageToShow.src}
						alt={imageToShow.alt}
						srcSet={
							isPhone
								? `
					${imageToShow.sizes[0]} 500w,
					${imageToShow.sizes[1]} 350w
				`
								: `
					${imageToShow.sizes[0]} 800w,
					${imageToShow.sizes[1]} 500w,
					${imageToShow.sizes[2]} 350w
				`
						}
						webpSrcSet={
							isPhone
								? `
					${imageToShow.webpSizes[0]} 500w,
					${imageToShow.webpSizes[1]} 350w
				`
								: `
					${imageToShow.webpSizes[0]} 800w,
					${imageToShow.webpSizes[1]} 500w,
					${imageToShow.webpSizes[2]} 350w
				`
						}
						sizes="(min-width: 1040px) 60vw, (min-width: 768px) 40vw, (max-width: 767px) 100vw, 800px"
					/>
				</ImageContainer>
			)}
		</div>
	);
};

export default ThemeBannerBlock;
